p{
  margin-bottom: 1em;
}

.details-container{
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  z-index: 999;
  white-space: nowrap;
  padding: 0 0.5em;
  margin-top: 10px;
  border-radius: 5em;
  @include mq(sm){
    margin-top: 20px;
  }
  .btn-content{

  }
  .author{
    display: none;
    @include mq(sm){
      display: inline;
    }
  }
}

.author-container{
  display: flex;
  justify-content: center;
  margin-top: 0px;
  @include mq(sm){
    display: none;
  }
}

.article-title{
  padding-top: 45px;
  @include mq(sm){
    padding-top: 60px;
  }
}

.article-chapeau{
  text-align: center;
  padding: 0 10px;
  @include mq(sm){
    padding: 0 20px;
  }
  h2{
    margin-top: 1em;
    margin-bottom: 0.25em;
  }
}

.text-area-content.content{
  padding: 10px;
  @include mq(sm){
    padding: 20px;
  }

  img{
    margin: 0 -20px;
    width: calc(100% + 40px);
  }

  figure + p, figure + h3{
    margin-top: 1em;
  }

  figure.video{
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow-y: hidden;
    margin: 0 -20px;
    width: calc(100% + 40px);
    background-color: black;
  }

  figure.video iframe{
    position: absolute;
    border: none;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
  }

  figure + h2{
    margin-top: 0.35em;
  }

  p{
    @include mq(sm){
      width: 66.6666%;
    }
  }

  h2,h3{
    margin-top: 0em;
    margin-bottom: 0.5em;
    @include mq(sm){
      margin-bottom: 0.3em;
      margin-top: -0.2em;
    }
  }

  em{
    margin-right: 1em;
  }

}

.slideshow{
  margin-top: 3.25em;

  @include mq(sm){
    min-height: 70.5vh;
  }
  height: 70.5vh;
  display: flex;
  flex-direction: column;
}

.swiper-legend{
  margin-top: 1em;
  text-align: center;
  font-size: var(--fs-small);
  text-transform: uppercase;
  transition: opacity 0.2s;
}

.swiper-container{
  position: relative;
  overflow: hidden;
  height: 100%;
  flex:1;
}
.swiper-wrapper{
  height: 100%;
}
.swiper-slide{
  display: flex;
  height: 100% !important;
  img{
    &.portrait{
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center center;
    }
    &.landscape{
      height: 100%;
      object-fit: cover;
      object-position: top;
      margin: 0 auto;
      @include mq(md){
        object-fit: contain;
      }
    }


  }
}

.swiper-slide-prev{
  img{
    object-position: top right !important;
    @include mq(sm){
      object-position: center right !important;
    }
  }
}

.swiper-slide-next{
  img{
    object-position: top left !important;
    @include mq(sm){
      object-position: center left !important;
    }
  }
}

.swiper-button{
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: 99;
  cursor: pointer;
}

.swiper-next{
  top:0;
  right: 0;
  opacity: 1;
}

.swiper-prev{
  top:0;
  left: 0;
  opacity: 1;
}

.credits{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 3.8em;
  margin-top: 3.8em;
  .btn{
    margin: 0.5em;
    height: auto;
    min-height: 3.3em;
    .btn-content{
      text-align: center;
    }
  }
}

.read-more-content{
  text-align: center;
  .btn{
    margin-bottom: 1.5em;
  }
  h1{
    display: inline;
  }
}

.footnote{
  a{
    position: relative;
    display: inline-block;
  }
}


.footnotes-list li{
  &:before{
    content: attr(value)".";
  }
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  font-size: var(--fs-small);
  text-transform: uppercase;
  padding: 1.2em 1.5em;
  min-width: 200px;
  height: var(--height);
  line-height: 1.2em;
  border-radius: calc(var(--height) * 0.5);
  color: black;
  z-index: 99999;
  text-align: center;
  background: rgba(0,0,0,0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  span{
    display: none;
  };
}