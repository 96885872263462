// CONFIGURATE DOCUMENT

$fontsize: (
  main: (
    xs: (
      fs: 5.12vw,
      lh: 1em,
      ls: 0.05em,
    ),
    sm: (
      fs: 1.6vw,
      lh: 1em,
      ls: 0.05em,
    ),
    md: (
      fs: 1.6vw,
      lh: 1em,
      ls: 0.05em,
    ),
  ),
  headline2: (
    xs: (
      fs: 7.69vw,
      lh: 1em,
      ls: 0.04em,
    ),
    sm: (
      fs: 3.33vw,
      lh: 1em,
      ls: 0.04em,
    ),
    md: (
      fs: 3.33vw,
      lh: 1em,
      ls: 0.04em,
    ),
  ),
  small: (
      xs: (
        fs: 10px,
        lh: 1.03em,
        ls: 0.08em,
      ),
      sm: (
        fs: 12px,
        lh: 1.03em,
        ls: 0.08em,
      ),
      md: (
        fs: 12px,
        lh: 1.03em,
        ls: 0.08em,
      ),
  ),
);

$margin: (
  sm: 1,
);

:root {
  --base-space: 8px;
  --color-text: black;
  --color-link: black;
  --color-bg: white;
}

// __________________________________________________________ //

// FONTS

$fonts: (
  main: (
    name: FuturaSHOP-DemBol,
    fallback: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    withFile: false,
  ),
  headline2: (
    name: FuturaSHOP-DemBol,
    fallback: "FuturaSHOP-DemBol",
    withFile: true,
  ),
  small: (
    name: FuturaSHOP-DemBol,
    fallback: "FuturaSHOP-DemBol",
    withFile: true,
  ),
  icons: (
    name: icons,
    fallback: "Icons",
    withFile: true,
  ),



);

// __________________________________________________________ //

// BREAKPOINTS

$breakpoints: (
  xs: 0px,
  sm: 768px,
  md: 1024px,
);

// __________________________________________________________ //

// TRANSITION

$transition-property-default: all;
$transition-duration-default: 0.3s;
$transition-timing-default: cubic-bezier(0.7, 0, 0.3, 1);
$transition-delay-default: 0s;

// __________________________________________________________ //

// FREE CONFIG
