*::-webkit-scrollbar{
  display: none;
}

*{
  scrollbar-width: none;
}

::selection {
  background-color: black;
  color: white;
}

@font-face {
  font-family: "Imago Medium";
  src: url(../fonts/ImagoPro-Bold.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

img {
  display: block;
  text-align: center;
}

html, body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ajax-container-visible{
  header{
    width: auto;
    .btn:last-of-type{
      display: none;
    }
  }
}

header {
  position: fixed;
  z-index: 9999999;
  padding: 10px;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  @include mq(sm){
    padding: 20px;
  }
}

main {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  display: block;
  .content-container{
    display: flex;
    flex-direction: column;
  }
}

picture img {
  display: block;
  text-align: center;
}

header {
  position: fixed;
  color: white;
  z-index: 9999999;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
}




h1 {
  font-family: "Imago Medium" !important;
  font-size: 10.5vw !important;
  text-align: center;
  line-height: 1em;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  margin: 0;
  background-position-y: 200px;
  @include mq(sm){
    font-size: 9vw !important;
    line-height: 97%;
  }
}

.menu-toggle, .close-btn{
  cursor: pointer;
  &.hide{
    display: none;
  }
}

.btn-title{
  left: 50%;
  transform: translateX(-50%);
}

.btn {
  @include font($font: small);
  position: relative;
  padding: 0.5em 1.5em;
  height: 3.3em;
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 5em;
  overflow: hidden;
  text-transform: uppercase;
  img{
    mix-blend-mode: difference;
    height: 1em;
    display: inline;
    line-height: 2em;
    width: auto;
  }

}

.btn .btn-content {
  color: black;
  overflow: hidden;
  img{
    height: 1em;
    display: inline;
    line-height: 2em;
    position: relative;
    width: auto;
  }
  p{
    margin-bottom: 0;
  }
}
.btn .blurred-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  &:before {
    content: '';
    left:0; top:0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
}

.btn.logo{
  @include font($font: icons);
  &:before{
    content: "\f101";
    color: black;
  }
}

.btn.btn-center{
  left:50%;
  transform: translateX(-50%);
}

.blurred-btn {
  height: 100%;
  overflow: hidden;
  border-radius: 5em;
  padding: 0.25em;
  text-align: center;
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
}

.blurred-btn{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  border-radius: 5em;
  z-index: -1;
  margin: -1px;
}

.post {
  position: relative;
}
.post a{
  display: block;
  position: relative;
}
.post .title-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0;
  @include mq(sm){
    padding: 0;
  }
  .title{
    position: sticky;
    top: 10px;
    @include mq(sm){
      top: 20px;
    }
  }
  .details-container{
    position: relative;
    top: 0em;
    transform: translate(0);
    align-items: center;
    justify-content: center;
    left:0;
    margin-top: 10px;
    margin-bottom: 3px;
    @include mq(sm){
      margin-top: 20px;
      margin-bottom: 0em;

    }
  }
  .author-container{
    margin-top: 0px;
    @include mq(sm){
      margin-top: 0.1em;
    }
  }
}
.post h1 {
  top: 10px;
  background-clip: unset;
  background-repeat: no-repeat;
  background-size: 0px;
  -webkit-text-fill-color: black !important;
  z-index: 99;
  transform: translate3d(0,0,0);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  @include mq(sm){
    top: 0px;
    background-size: cover !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    padding-top: 0px;
  }
  &:hover{
    -webkit-text-fill-color: black !important;
    -webkit-background-clip: none !important;
    background-image: none !important;
  }
}


.post .image-wrap{
  width: 100%;
  padding-top: 125%;
  position: relative;
  z-index: -1;
  picture{
    position: absolute;
    top:0;
    height: 100%;
    width: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @include mq(sm){
    width: 100%;
    padding-top: 0;
    position: static;
    z-index: -1;
    picture{
      position: static;
      height: auto;
      width: 100%;
      img{
        width: 100%;
        height: auto;
        object-fit: unset;
      }
    }
  }
}


.btn-target {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  z-index: 99999999;
  display: none;
  padding-top: 45px;
  @include mq(sm){
    padding-top: 60px;
  }
  h1{
    pointer-events: auto;
    width: 100%;
    display: block;
  }
}
.btn-target.active {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.menu-active {
  main{
    filter: blur(15px);
    pointer-events: none;
  }
  #ajax-container{
    filter: blur(15px);
    pointer-events: none;
  }

}

.newsletter{
  background-color: yellow;
  padding: 0.3em;
}

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 1.8em;
  overflow-x: hidden;
  background-color: yellow;
  a{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 34s linear infinite;
  height: 100%;
  .content{
    height: 100%;
    vertical-align: middle;
  }
  span{
    margin-left: 2em;
    line-height: 1.8em;
  }
}

@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}



#ad{
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  opacity: 1;
  transition: opacity 0.25s;

  .close-btn {
    position: fixed;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
    @include mq(sm) {
      left: 50%;
      top: 20px;
    }
  }

  &.hide{
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s;
  }

  .content-wrapper{
    position: absolute;
    background-color: red;
    max-height: 600px;
    min-width: calc(100% - 20px);
    aspect-ratio: 3/2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    @include mq(sm){
      min-height: 600px;
      min-width: unset;
    }
    picture img{
      object-fit: cover;
      position: relative;
    }
  }
}

#ajax-container {
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  display: block;
  z-index: 999999;
  overflow-y: scroll;
  overflow-x: hidden;
  opacity: 1;
  transition: opacity 0.25s;

  &[data-page="about"]{
    background-color: red;
    ::selection {
      background-color: black;
      color: red !important;
    }
  }
  .close-btn{
    position: fixed;
    z-index: 999;
    right: 10px;
    top: 10px;
    @include mq(sm){
      right: 20px;
      top: 20px;
    }
  }
  &.hide{
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s;
  }
}

.ajax-content{
  transition: opacity 0.3s ease-in;
}

.about-contact, .about-design{
  text-align: center;
  .btn{
    margin-top: 0.75em;
  }
}
.about-contact h1 {
  display: block;
}

.about-logo {
  width: 50%;
  margin: 2.9em auto 3.8em;
}
.about-logo img {
  width: 100%;
}

div[data-page="about"] .content-container {
  padding-top: 45px;
  @include mq(sm){
    padding-top: 60px;
  }
}
div[data-page="about"] .content-container figure {
  @include mq(sm) {
    width: 32%;
    margin: 2.9em auto 3.8em;
  }
  width: 100%;
}